
    import { Component, Vue, Watch } from 'vue-property-decorator';
    import { deleteUser, fetchUsers } from '@/api/User';
    import { User as UserType } from '@/interfaces/user/User';
    import { BulkAction } from '@/enums/BulkAction';
    import auth from '@/store/auth/auth';

    @Component
    export default class UserList extends Vue {
        private data: UserType[] = [];
        private checkedItems: UserType[] = [];
        private actions: unknown;
        private bulkAction: BulkAction | null = null;

        @Watch('bulkAction')
        onBulkActionChanged() {
            if (this.bulkAction === BulkAction.DELETE) {
                this.$buefy.dialog.confirm({
                    title: 'CRM-Benutzerkonten löschen',
                    message:
                        'Bist du sicher, dass die ausgewählten Benutzerkonten <b>gelöscht</b> werden sollen?',
                    confirmText: 'CRM-Benutzerkonten löschen',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: async () => {
                        await this.dispatchDeleteUsers();
                        this.data = await this.fetchUsers();
                        this.$buefy.toast.open('Konten gelöscht!');
                        this.bulkAction = null;
                    },
                    onCancel: () => {
                        this.bulkAction = null;
                    },
                });
            }
        }

        async dispatchDeleteUsers() {
            for (const user of this.checkedItems) {
                await this.deleteUser(user);
            }
        }

        async deleteUser(user: UserType) {
            let response = await deleteUser(user);

            if (response && (response.status === 401 || response.status === 422)) {
                await auth.dispatchRefreshToken();

                response = await deleteUser(user);
            }

            if (!response || !(await response.parsedBody) || response.status !== 200) {
                throw new Error('Error while deleting Users');
            }
        }

        async fetchUsers() {
            let response = await fetchUsers();

            if (response && (response.status === 401 || response.status === 422)) {
                await auth.dispatchRefreshToken();

                response = await fetchUsers();
            }

            const users = await response.parsedBody;

            if (users) {
                return users;
            } else {
                throw new Error('Error while getting users.');
            }
        }

        async mounted() {
            this.data = await this.fetchUsers();
        }

        beforeCreate() {
            this.actions = BulkAction;
        }
    }
